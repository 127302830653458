import { ISurvey } from "../../models/survey.model";
import { MantineColor, Paper, Title } from "@mantine/core";
import { DataTable, ITableHeadProps } from "../dataTable/dataTable";

export interface ISurveyTableProps extends ITableHeadProps<ISurvey> {
	title?: string;
	color?: MantineColor;
}

export function SurveyTable(props: ISurveyTableProps) {
	const {title, color, ...tableProps} = props;

	return (
		<Paper
			withBorder
			radius={"lg"}
			style={{overflow: "hidden"}}
		>
			<Paper
				bg={props.color}
				radius={0}
				px={"md"}
				py={"xs"}
			>
				<Title
					size={"h3"}
					color={`${props.color}.0`}
				>
					{props.title ?? "Surveys"}
				</Title>
			</Paper>
			<DataTable
				{...tableProps}
			/>
		</Paper>
	);
}