import { PropsWithChildren, ReactNode } from "react";
import { Avatar, Group, Stack, Text } from "@mantine/core";

export interface ISurveyField {
	label: ReactNode;
	value: ReactNode;
	icon: ReactNode;
}

export function SurveyField(props: PropsWithChildren<ISurveyField>) {
	return (
		<Group position={"apart"} noWrap sx={{flex: 1}}>
			<Group noWrap sx={{flex: 1}}>
				<Avatar
					size={"md"}
					color={"cyan"}
				>
					{props.icon}
				</Avatar>
				<Stack spacing={4} sx={{flex:1}}>
					<Text size={"sm"} weight={500} truncate>{props.label}</Text>
					<Text truncate>{props.value}</Text>
				</Stack>
			</Group>
			{props.children}
		</Group>
	);
}