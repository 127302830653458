import { Config } from "../helpers/config";
import AppWrapper from "../components/appWrapper/appWrapper";
import Seo from "../components/seo/seo";
import { Stack, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ISurvey } from "../models/survey.model";
import ApiError from "../interfaces/apiError";
import { useApi } from "../context/api.context";
import { SurveyTable } from "../components/surveyList/surveyTable";
import { useSurveyColumns } from "../hooks/table.columns";
import { ErrorBoundary } from "../components/errorBoundary/errorFallback";
import { ReportsContent } from "./reports";
import { ScheduleRunDates } from "../components/scheduleRunDates/scheduleRunDates";
import { PermissionCheck } from "../components/permissionCheck/permissionCheck";

function DashboardContent() {
	const api = useApi();
	const columns = useSurveyColumns();

	const surveysQuery = useQuery<ISurvey[], ApiError>(
		["surveys.GetOutstandingSupportRequests", api.company],
		api.surveys.GetOutstandingSupportRequests,
		{
			placeholderData: [],
			enabled: !!api.company,
		},
	);

	return (
		<>
			<ErrorBoundary>
				<ScheduleRunDates/>
			</ErrorBoundary>
			<ErrorBoundary>
				<SurveyTable
					title={"Outstanding Support Requests"}
					color={"cyan"}
					data={surveysQuery.data || []}
					columns={columns}
					loading={surveysQuery.isLoading || surveysQuery.isFetching}
					stickyHeader
					enableLocalSorting
				/>
			</ErrorBoundary>
		</>
	);
}

export default function Dashboard() {
	return (
		<AppWrapper>
			<Seo
				title={"Dashboard"}
				description={`Dashboard for ${Config.title}`}
			/>
			<Stack>
				<Title>Dashboard</Title>
				<ErrorBoundary>
					<PermissionCheck permission={"canReadSurveys"}>
						<DashboardContent/>
					</PermissionCheck>
				</ErrorBoundary>
				<ErrorBoundary>
					<PermissionCheck permission={"showReportAsDashboard"}>
						<ReportsContent/>
					</PermissionCheck>
				</ErrorBoundary>
			</Stack>
		</AppWrapper>
	);
}