import { Group, Paper } from "@mantine/core";
import { SurveyField } from "../survey/surveyField";
import { GetFormattedDate } from "../../helpers/helpers";
import { TbCalendarStats, TbHistory } from "react-icons/tb";
import { useApi } from "../../context/api.context";

export function ScheduleRunDates() {
	const api = useApi();

	return (
		<Group>
			<Paper
				withBorder
				p={"md"}
				radius={"lg"}
				sx={{flex: 1}}
			>
				<SurveyField
					label={"Last Scheduled Send"}
					value={GetFormattedDate(api.company?.scheduleLastRunDate, "eeee, dd MMMM yyyy h:mm a") || "-"}
					icon={<TbHistory size={24}/>}
				/>
			</Paper>
			<Paper
				withBorder
				p={"md"}
				radius={"lg"}
				sx={{flex: 1}}
			>
				<SurveyField
					label={"Next Scheduled Send"}
					value={GetFormattedDate(api.company?.scheduleNextRunDate, "eeee, dd MMMM yyyy h:mm a") || "Disabled"}
					icon={<TbCalendarStats size={24}/>}
				/>
			</Paper>
		</Group>
	);
}