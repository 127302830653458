import React, { CSSProperties, PropsWithChildren, ReactNode, useMemo, useState } from "react";
import { Center, Group, Loader, ScrollArea, Stack, Table, Text, Tooltip, useMantineTheme } from "@mantine/core";
import tableStyles from "./dataTable.module.scss";
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getSortedRowModel, SortDirection,
	SortingState,
	useReactTable
} from "@tanstack/react-table";
import { TbArrowDown, TbArrowUp } from "react-icons/tb";

export interface ITableHeadProps<T> {
	data: T[];
	columns: ColumnDef<T>[];
	orderBy?: string;
	descending?: boolean;
	loading?: boolean;
	maxHeight?: string | number;
	stickyHeader?: boolean;
	headerColor?: string;
	className?: string;
	enableLocalSorting?: boolean;
	noItemsText?: ReactNode;
	requireDoubleClick?: boolean;

	onColumnSort?(value: string | SortingState): void;

	onRowSelected?(item: T): void;
}

function TableContent<T>(props: PropsWithChildren<ITableHeadProps<T>>): JSX.Element {
	const theme = useMantineTheme();
	const [sorting, setSorting] = useState<SortingState>([]);

	const data = useMemo(() => props.data, [props.data]);

	const table = useReactTable({
		data,
		columns: props.columns,
		state: {
			sorting,
		},
		defaultColumn: {
			minSize: 50
		},
		columnResizeMode: "onChange",
		getCoreRowModel: getCoreRowModel(),
		enableSorting: props.enableLocalSorting,
		onSortingChange: (e) => setSorting(e),
		getSortedRowModel: props.enableLocalSorting ? getSortedRowModel() : undefined,
	});

	const icons = useMemo(() => {
		return {
			"asc": <TbArrowUp/>,
			"desc": <TbArrowDown/>
		};
	}, []);

	const headerStyles: CSSProperties | undefined = useMemo(() => {
		return props.stickyHeader
			? {
				position: "sticky",
				top: 0,
				borderBottom: `1px solid ${theme.colors.gray[3]}`
			}
			: undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.stickyHeader, props.headerColor]);

	return (
		<Table
			striped
			highlightOnHover
			w={"100%"}
			className={props.className}
			{...{
				style: {
					tableLayout: "fixed"
				}
			}}
		>
			<thead style={headerStyles}>
			{table.getHeaderGroups().map(headerGroup => (
				<tr
					key={headerGroup.id}
					className={tableStyles.tr}
				>
					{headerGroup.headers.map(header => (
						<th
							key={header.id}
							{...{
								style: {
									width: header.getSize() !== 0 ? header.getSize() : undefined,
									borderBottom: "none"
								},
								className: tableStyles.th,
								onClick:
									props.enableLocalSorting
										? header.column.getToggleSortingHandler()
										: () => (props.onColumnSort && !header.column.getIsResizing())
											? header.column.getCanSort() ? props.onColumnSort(header.id) : null
											: null
							}}
						>
							{
								flexRender(
									<Group position={"apart"} noWrap>
										{
											typeof header.column.columnDef.header === "string" &&
											<Tooltip label={header.column.columnDef.header} openDelay={1000}>
												<Text truncate>
													{header.column.columnDef.header}
												</Text>
											</Tooltip>
										}
										{
											props.enableLocalSorting && (icons[header.column.getIsSorted() as SortDirection] ?? null)
										}
										{
											(props.onColumnSort && !props.enableLocalSorting) &&
											header.id === props.orderBy &&
											(icons[props.descending ? "desc" : "asc"] ?? null)
										}
									</Group>,
									header.getContext()
								)
							}
							{
								header.column.getCanResize() &&
								<div
									{...{
										className: `${tableStyles.resizer} ${header.column.getIsResizing() ? tableStyles.isResizing : ''}`,
										onMouseDown: header.getResizeHandler(),
										onTouchStart: header.getResizeHandler(),
										onClick: (e) => {
											e.preventDefault();
											e.stopPropagation()
										},
									}}
								/>
							}
						</th>
					))}
				</tr>
			))}
			</thead>
			<tbody>
			{table.getRowModel().rows.map(row => (
				<tr
					key={row.id}
					onDoubleClick={() => props.requireDoubleClick ?
						props.onRowSelected
							? props.onRowSelected(row.original)
							: null
						: null
					}
					onClick={() => props.requireDoubleClick
						? null
						: props.onRowSelected
							? props.onRowSelected(row.original)
							: null
					}
					className={tableStyles.tr}
				>
					{row.getVisibleCells().map(cell => (
						<td
							key={cell.id}
							{...{
								style: {
									width: cell.column.getSize() !== 0 ? cell.column.getSize() : undefined,
								}
							}}
							className={tableStyles.td}
						>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</td>
					))}
				</tr>
			))}
			</tbody>
		</Table>
	);
}

export function DataTable<T>(props: PropsWithChildren<ITableHeadProps<T>>): JSX.Element {
	return (
		<ScrollArea.Autosize
			type={"hover"}
			mah={props.maxHeight}
			styles={{scrollbar: {zIndex: 2}}}
			sx={{overflowX: "auto", overflowY: "hidden"}}
		>
			<Stack spacing={0}>
				<TableContent {...props}/>
				{
					!props.children &&
					<>
						{
							props.loading &&
							<Center w={"100%"} p={"md"}>
								<Loader/>
							</Center>
						}
						{
							(!props.data.length && !props.loading) &&
							<Center w={"100%"}>
								{
									props.noItemsText ??
									<Text color={"dimmed"} size={"lg"} p={"md"}>No items</Text>
								}
							</Center>
						}
					</>
				}
				{props.children}
			</Stack>
		</ScrollArea.Autosize>
	);
}