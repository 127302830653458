import { ActionIcon, Box, Group, ScrollArea, Tooltip } from "@mantine/core";
import { TbNote } from "react-icons/tb";
import { CustomModal } from "../customModal/customModal";
import { useState } from "react";
import { Notes } from "./notes";
import { ISurvey } from "../../models/survey.model";

export interface INotesActionIconProps {
	survey?: ISurvey;
}

export function NotesActionIcon(props: INotesActionIconProps) {
	const [modalOpen, setModalOpen] = useState(false);

	function onClose() {
		setModalOpen(false);
	}

	return (
		<>
			<Group
				position={"center"}
				w={"100%"}
			>
				<Tooltip label={`Notes (${props.survey?.noteCount ?? 0})`}>
					<ActionIcon
						variant={"filled"}
						color={"grape"}
						size={"lg"}
						onClick={() => setModalOpen(true)}
					>
						<TbNote size={18}/>
					</ActionIcon>
				</Tooltip>
			</Group>
			<CustomModal
				opened={modalOpen}
				onClose={onClose}
				title={"View Notes"}
				color={"grape"}
				size={500}
				scrollAreaComponent={ScrollArea.Autosize}
			>
				<Box mt={"md"}>
					<Notes survey={props.survey}/>
				</Box>
			</CustomModal>
		</>
	);
}