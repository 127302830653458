import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ISurvey } from "../models/survey.model";
import { useMemo } from "react";
import { Text } from "@mantine/core";
import { GetFormattedDate } from "../helpers/helpers";
import { AssignUserActionIcon } from "../components/assignUser/assignUserActionIcon";
import Link from "next/link";
import { CompleteSurveyButton } from "../components/completeSurveyButton/completeSurveyButton";
import { NotesActionIcon } from "../components/notesButton/notesActionIcon";
import { ResponseIcons } from "../components/surveyList/responseIcons";
import { SurveyStatusBadge } from "../components/surveyList/surveyStatus";
import { SmsStatusBadge } from "../components/surveyList/smsStatusBadge";

export const useSurveyColumns = (): ColumnDef<ISurvey>[] => {

	return useMemo(() => {
		const columnHelper = createColumnHelper<ISurvey>();
		return [
			columnHelper.accessor("employee.firstName", {
				header: "First Name",
				cell: i => (
					<Text
						component={Link}
						href={`/surveys/${i.row.original.id}`}
						truncate
					>
						{i.getValue()}
					</Text>
				),
				size: 150
			}),
			columnHelper.accessor("employee.lastName", {
				header: "Last Name",
				cell: i => (
					<Text
						component={Link}
						href={`/surveys/${i.row.original.id}`}
						truncate
					>
						{i.getValue()}
					</Text>
				),
				size: 150
			}),
			columnHelper.accessor("createdDate", {
				header: "Created",
				cell: i => <Text truncate>{GetFormattedDate(i.getValue())}</Text>,
				size: 110,
				enableResizing: false
			}),
			columnHelper.accessor("status", {
				header: "Status",
				cell: i => <SurveyStatusBadge status={i.getValue()}/>,
				size: 120,
				enableResizing: false
			}),
			columnHelper.display({
				header: "Support Requests",
				cell: i => <ResponseIcons survey={i.row.original}/>,
				size: 210,
				enableResizing: false,
				enableSorting: false
			}),
			columnHelper.accessor("smsStatusCode", {
				header: "SMS",
				cell: i => <SmsStatusBadge status={i.getValue()} fullWidth/>,
				size: 50,
				enableResizing: false
			}),
			columnHelper.accessor("assignedUser", {
				header: "Assigned",
				cell: i => <AssignUserActionIcon survey={i.row.original}/>,
				size: 80,
				enableResizing: false
			}),
			columnHelper.display({
				id: "notes",
				cell: i => <NotesActionIcon survey={i.row.original}/>,
				size: 60,
				enableResizing: false,
				enableSorting: false
			}),
			columnHelper.display({
				id: "complete",
				cell: i => <CompleteSurveyButton survey={i.row.original} variant={"icon"}/>,
				size: 60,
				enableResizing: false,
				enableSorting: false
			}),
		] as ColumnDef<ISurvey> [];
	}, []);
};