import { ActionIcon, Avatar, Group, Text, Tooltip } from "@mantine/core";
import { TbUser } from "react-icons/tb";
import { useState } from "react";
import { useApi } from "../../context/api.context";
import { useColorInitials } from "../../hooks/helpers.hooks";
import { AssignUserModal } from "./assignUserModal";
import { ISurvey } from "../../models/survey.model";
import { SurveyStatus } from "../../models/surveyStatus.enum";
import { useApp } from "../../context/app.context";

export interface IAssignActionIconProps {
	survey?: ISurvey;
}

export function AssignUserActionIcon(props: IAssignActionIconProps) {
	const api = useApi();
	const app = useApp();
	const [modalOpen, setModalOpen] = useState(false);

	const userName = api.adminUsers.GetUserName(props.survey?.assignedUser);
	const colorInitials = useColorInitials(userName);

	return (
		<>
			<Tooltip
				label={props.survey?.assignedUser ? userName : "Assign a user"}
			>
				<Group
					position={"center"}
					w={"100%"}
				>
					{
						(props.survey?.status !== SurveyStatus.Completed && app.permissions.canEditSurveys)
							? <ActionIcon
								variant={props.survey?.assignedUser ? "light" : "filled"}
								color={props.survey?.assignedUser ? colorInitials.color : undefined}
								radius={props.survey?.assignedUser ? "xl" : undefined}
								size={"lg"}
								onClick={() => setModalOpen(true)}
								disabled={api.fetching}
							>
								{
									props.survey?.assignedUser
										? <Text
											weight={700}
											size={"sm"}
										>
											{colorInitials.initials}
										</Text>
										: <TbUser size={24}/>
								}
							</ActionIcon>
							: <Avatar
								variant={"light"}
								color={colorInitials.color}
								radius={"xl"}
								size={34}
							>
								<Text
									weight={700}
									size={"sm"}
								>
									{colorInitials.initials}
								</Text>
							</Avatar>
					}
				</Group>
			</Tooltip>
			<AssignUserModal
				surveyId={props.survey?.id}
				userId={props.survey?.assignedUser}
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			/>
		</>
	);
}